$highlightcolor: navy;

body {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  letter-spacing: 0.05em;
}

h1, h2 {
  margin: 2% auto;
  text-align: center;
  font-weight: 400;
}

button {
  border: 2px solid $highlightcolor;
  padding: 15px;
}

header {
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    max-width: 1em;
    vertical-align: baseline;
  }
}

.pets-wrapper {
  display: flex;

  .cat,
  .dog {
    width: 50%;
    text-align: center;
  }
}

.cat-image, .dog-image{
  width: 200px;
  margin: auto;
  height: 300px;
  width: 200px;
  border: 3px black solid;
 }

 .pet-info{
   text-align: left;
   margin: auto;
   width: 60%;
 }

 .cat-queue, .dog-queue {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  border: 4px black solid;
  margin: 5% auto;
  width: 60%;

}

ul{
  padding: 0;
}

.thumbnail {
  flex: 20%;
  list-style-type: none;
  margin-top: 4px;
}

.thumbnail img {
  height: 60px;
  width: 60px;
}

.pet-info {
  background: rgb(238, 238, 238);
  padding: 10px;
  margin: 5% auto;
  border-radius: 15px;
}

// updated styles
.landing-page {
  text-align: center;
  img {
    display: block;
    margin: 0 auto 25px;
    max-width: 100%;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 600px;
    }
  }
  button {
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
  }
}

.intro-text {
  max-width: 40ch;
  margin: 0 auto 25px;
  @media (min-width: 768px) {
    max-width: 75ch;
  }
}
